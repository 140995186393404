<template>
  <div
    class="datasource-container"
    v-if="account !== {} && filterExtrl.length > 0"
  >
    <v-card elevation="0" class="dense">
      <v-card-title
        >基础信息
        <a @click.stop="show_sheet('edit', 'ACCOUNT')">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <span>姓名：</span>
            <span
              :style="{
                color: attributes.fullname ? '' : '#9195a3'
              }"
            >
              {{ attributes.fullname || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>登录名：</span>
            <span
              :style="{
                color: account.login_name ? '' : '#9195a3'
              }"
            >
              {{ account.login_name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>外部ID：</span>
            <span
              :style="{
                color: account.external_id ? '' : '#9195a3'
              }"
            >
              {{ account.external_id || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row align-center">
          <v-col>
            <span>帐户来源：</span>
            <span
              :style="{
                color: account.source_name ? '' : '#9195a3'
              }"
            >
              {{ account.source_name || "未知"
              }}<v-btn
                v-if="
                  Object.prototype.toString.call(account.source_id) ===
                    '[object Number]'
                "
                text
                icon
                color="primary"
                :to="`/source/view/${account.source_id}`"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              >
            </span>
          </v-col>
          <v-col>
            <span>手机号码：</span>
            <span
              :style="{
                color: attributes.mobilephone ? '' : '#9195a3'
              }"
            >
              {{ attributes.mobilephone || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>邮箱地址：</span>
            <span
              :style="{
                color: attributes.emailaddress ? '' : '#9195a3'
              }"
            >
              {{ attributes.emailaddress || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>过期时间：</span>
            <span
              :style="{
                color: account.expiry_time ? '' : '#9195a3'
              }"
            >
              {{
                account.expiry_time
                  ? account.expiry_time.replace(/T/, " ")
                  : "" || "未知"
              }}
            </span>
          </v-col>
          <v-col
            ><span>是否禁用：</span>{{ account.disabled ? "是" : "否" }}</v-col
          >
          <v-col><span>帐户状态：</span>{{ "正常" }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense">
      <v-card-title
        >扩展属性
        <a @click.stop="show_sheet('edit', 'EXTRAL')">(修改)</a></v-card-title
      >
      <v-card-text>
        <v-row v-for="items in filterExtrl" :key="items.index">
          <v-col v-for="item in items" :key="item.id">
            <span v-if="item.display_name">{{ item.display_name }}：</span>
            <span
              v-if="item.display_name"
              :style="{
                color: attributes[item.name] || item.default ? '' : '#9195a3'
              }"
            >
              {{ attributes[item.name] || item.default || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      :is_modify="current_edit_type === 'edit'"
      type="edit"
      :entity_info="account"
      :editeFilterExtrl="editeFilterExtrl"
      v-if="account"
    />
  </div>
</template>

<script>
import AccountEditor from "@/views/account/drawer/AccountEditor";
import ExtralAttrEditor from "@/views/account/drawer/ExtralAttr";

const EDITORS = {
  ACCOUNT: AccountEditor,
  EXTRAL: ExtralAttrEditor
};

export default {
  name: "AccountInfo",
  props: ["account", "filterExtrl", "editeFilterExtrl"],
  data() {
    return {
      attributes: {},
      // drawer
      current_editor: null,
      current_edit_type: "create",
      drawer_status: [false, false],
      sheet_editor: null
    };
  },
  async created() {
    await this.getCurIdData();
  },
  methods: {
    getCurIdData() {
      this.attributes = this.account.attributes || {};
    },
    show_sheet(edit_type, type) {
      this.sheet_editor = EDITORS[type];
      this.current_edit_type = edit_type;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    }
  },
  watch: {
    account() {
      this.getCurIdData();
    }
  },
  components: {
    AccountEditor,
    ExtralAttrEditor
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
