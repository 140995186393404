<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See {type} sub adapter config", {
                  type: ""
                })}`
              : `${$t("application.Edit {type} sub adapter config", {
                  type: ""
                })}`
          }}
          <span
            v-if="
              account.login_name ||
                (account.attributes && account.attributes.fullname)
            "
          >
            ：{{ account.login_name || account.attributes.fullname }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子账户列表项
      </v-alert>

      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 应用名称</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="applicationName"
            :items="applications"
            item-text="name"
            item-value="id"
            return-object
            required
            :rules="rules.notNullRules"
            readonly="readonly"
            disabled="disabled"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 子账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            :auto-grow="true"
            rows="1"
            v-model="username"
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="applicationName.service_type === 'FORM_FILL'">
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 密码</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="password"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
            required
            :rules="rules.notNullRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="controlPwd"
            :type="showPassword ? 'text' : ''"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 描述</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="description"
            :readonly="is_readonly"
            :disabled="is_readonly"
            :auto-grow="true"
            rows="1"
            persistent-hint
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="save">提交</v-btn>
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { modify_subApplication } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SubApplication",
  props: ["curSubData", "is_readonly", "account"],
  data() {
    let isRightName = value => {
      return value;
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      loginName: "",
      username: null,
      password: null,
      description: "",
      account_id: "",
      applicationName: "",
      applications: [],
      curSubId: "",
      applicationId: "",
      isExist: false,
      showPassword: false,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isExistName: [v => isRightName(v) || "请输入正确的账号名"]
      }
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          claims: {
            username: this.username,
            password: this.password
          },
          description: this.description
        };
        const promise = modify_subApplication(
          this.applicationId,
          this.curSubId,
          payload
        )
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "子账户更新成功";
          })
          .catch(({ code, message }) => {
            throw `子账户修改失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async fetchData() {
      let allApplications = await this.refreshList();
      this.showPassword = false;
      let {
        id,
        claims,
        description,
        application_name,
        application_id
      } = this.curSubData;
      this.applicationName = allApplications.find(
        item => item.name == application_name
      );
      this.curSubId = id;
      this.applicationId = application_id;
      this.username = claims.username;
      this.password = "........";
      this.description = description;
    },
    controlPwd() {
      if (!this.showPassword) {
        return this.$http
          .get(
            `api/application/${this.applicationId}/sub_account/${this.curSubId}/password`
          )
          .delegateTo(api_request)
          .then(({ password }) => {
            this.password = password;
            this.showPassword = !this.showPassword;
          })
          .catch(({ code, message }) => {
            throw `获取密码失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        this.password = "........";
        this.showPassword = !this.showPassword;
      }
    },
    refreshList() {
      return this.$http
        .get(`api/application/list?offset=0&limit=1000000`)
        .delegateTo(api_request)
        .then(res => {
          this.applications = res.applications.filter(
            item => item.service_type !== "WebEXP"
          );
          return this.applications;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (value) {
          this.fetchData();
        }
      }
    }
  }
};
</script>
