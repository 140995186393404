<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ $t("application.Create {type} sub adapter config", {}) }}
          <span
            v-if="
              account.login_name ||
                (account.attributes && account.attributes.fullname)
            "
          >
            ：{{ account.login_name || account.attributes.fullname }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form" :key="curSubId">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子账户列表项
      </v-alert>

      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 应用名称</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="applicationName"
            :items="applications"
            item-text="name"
            item-value="id"
            return-object
            required
            :rules="rules.notNullRules"
            @change="selApplication(applicationName)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 子账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            :auto-grow="true"
            rows="1"
            v-model="claims.username"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="applicationName && applicationName.service_type === 'FORM_FILL'"
      >
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 密码</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="claims.password"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
            required
            :rules="rules.notNullRules"
            type="text"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 描述</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="description"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonlySub">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="create"
            >创建</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { create_subApplication } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SubApplication",
  props: [
    "subId",
    "is_readonlySub",
    "subType",
    "curSubData",
    "curProviderType",
    "account"
  ],
  data() {
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      applicationName: null,
      claims: {},
      description: "",
      account_id: "",
      curSubId: "",
      isExist: false,
      allLoginNames: [],
      applications: [],
      showPassword: false,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isExistName: [v => !!v || "请输入正确的账号名"]
      }
    };
  },
  async mounted() {
    await this.refreshList();
  },
  methods: {
    selApplication(applicationName) {
      this.applicationName = applicationName;
    },
    async create() {
      if (this.$refs.form.validate()) {
        let payload = {
          account_id: this.account.id,
          claims: this.claims,
          description: this.description
        };

        create_subApplication(this.applicationName.id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "子账户创建成功";
          })
          .catch(({ code, message }) => {
            throw `子账户创建失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    allAccounts() {
      // 调取所有账户列表数据
      return this.$http
        .get(`api/account/list?offset=0&limit=1000`)
        .delegateTo(api_request)
        .then(res => {
          this.allLoginNames = res.accounts;
          return res.accounts;
        })
        .catch(({ code, message }) => {
          throw `获取所有账号名失败：${this.$t(
            `${"api." + code}`
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    refreshList() {
      return this.$http
        .get(`api/application/list?offset=0&limit=100000`)
        .delegateTo(api_request)
        .then(res => {
          this.applications = res.applications.filter(
            item => item.service_type !== "WebEXP"
          );
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        this.reset();
      }
    }
  }
};
</script>
