<template>
  <div>
    <Breadcrumbs :curData="curName" goPage="/accounts" />
    <div class="main-wrapper">
      <TabList
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
        :curTabId="curTabId"
      />
      <div class="tabitem-wrapper">
        <component
          :is="curCom"
          :account="account"
          :editeFilterExtrl="editeFilterExtrl"
          :filterExtrl="filterExtrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { deepClone } from "@/util/util";
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import AccountInfo from "./component/AccountInfo";
import OrgInfo from "./component/OrgInfo";
import ExternalUser from "./component/ExternalUser";
import bus from "@/util/eventBus";

export default {
  name: "DataSource",
  data() {
    return {
      curTabId: 0,
      curCom: "AccountInfo",
      tabList: [
        {
          id: 0,
          name: "帐户信息"
        },
        {
          id: 1,
          name: "组织机构"
        },
        {
          id: 2,
          name: "外部用户"
        }
      ],
      account: {},
      editeFilterExtrl: [],
      filterExtrl: [],
      curName: null
    };
  },
  created() {
    this.fetchAccountDetail(this.$route.params.id);
    this.fetchAccountList();
    let tabId = this.$route.query.tabId;
    if (tabId != null) {
      this.curTabId = Number(tabId);
      this.curCom = ["AccountInfo", "OrgInfo", "ExternalUser"][this.curTabId];
    }

    bus.$off("refresh").$on("refresh", () => {
      this.fetchAccountDetail(this.$route.params.id);
      this.fetchAccountList();
    });
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
      window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
    }
  },
  methods: {
    tabEvent(id) {
      this.curTabId = id;
      this.$router.push({
        path: `/account/view/${this.$route.params.id}`,
        query: {
          tabId: id
        }
      });
      history.pushState(null, null, document.URL);
    },
    fetchAccountList() {
      this.$http
        .get(`api/attribute/account/list`)
        .delegateTo(api_request)
        .then(data => {
          let extrlAttrs = data.attributes.filter(item => !item.virtual);
          if (extrlAttrs.length > 0) {
            let newExtrlArr = extrlAttrs.filter(
              item =>
                item.name !== "fullname" &&
                item.name !== "mobilephone" &&
                item.name !== "emailaddress"
            );
            this.editeFilterExtrl = deepClone(newExtrlArr);

            let result = [];
            for (let i = 0; i < newExtrlArr.length; i += 3) {
              result.push(newExtrlArr.slice(i, i + 3));
            }

            result.map(item => {
              let n = (3 - item.length) % 3;
              for (let i = 0; i < n; i++) {
                item.push("");
              }
              return item;
            });
            this.filterExtrl = result;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchAccountDetail(id) {
      return this.$http
        .get(`api/account/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.account = data;
          let curName = data.login_name || data.attributes.fullname;
          if (curName) {
            this.curName = curName;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    goBack() {
      this.$router.replace({ path: "/accounts" });
    }
  },
  watch: {
    curTabId(v) {
      this.curCom = ["AccountInfo", "OrgInfo", "ExternalUser"][v];
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  components: {
    Breadcrumbs,
    TabList,
    AccountInfo,
    OrgInfo,
    ExternalUser
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>

<style lang="less" scoped>
.main-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 27px;
  margin-bottom: 30px;
}
</style>
