<template>
  <div class="datasource-container">
    <v-card elevation="0" class="dense">
      <v-card-title>外部用户绑定</v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pl-4">
                  ID
                </th>
                <th class="text-left">
                  {{ $t("account.external.External Source Name") }}
                </th>
                <th class="text-left">
                  {{ $t("account.external.External Name") }}
                </th>
                <th class="text-left">
                  {{ $t("account.external.External Created") }}
                </th>
                <th class="text-center">
                  {{ $t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="externals.length > 0">
              <tr
                v-for="external in externals"
                :key="external.id"
                class="text-left"
              >
                <td class="pl-4">{{ external.id }}</td>
                <td>
                  <v-btn
                    depressed
                    link
                    :to="`/source/view/${external.source_id}`"
                    >{{ external.source_name }}</v-btn
                  >
                </td>
                <td>{{ external.name }}</td>
                <td>{{ external.created.replace(/T/, " ") }}</td>
                <td class="text-center">
                  <v-btn icon elevation="0" @click="unbind(external)">
                    <v-icon>
                      mdi-account-cancel
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="5" style="width:100%;color:#7b7b7b">
                  暂无数据
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense">
      <v-card-title
        >应用子账户 <a @click.stop="show_sheet">(新建)</a></v-card-title
      >
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pl-4">
                  ID
                </th>
                <th class="text-left">
                  {{ $t("Application Name") }}
                </th>
                <th class="text-left">
                  {{ $t("SubApplication Name") }}
                </th>
                <th class="text-left">
                  {{ $t("SubApplication Description") }}
                </th>
                <th class="text-center" style="width:7%">
                  {{ $t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="subLists && subLists.length > 0">
              <tr
                v-for="subItem in subLists"
                :key="subItem.id"
                class="text-left"
              >
                <td class="pl-4">{{ subItem.id }}</td>
                <td>{{ subItem.application_name }}</td>
                <td>{{ subItem.claims.username }}</td>
                <td>{{ subItem.description }}</td>
                <td class="text-center">
                  <v-btn icon @click.stop="edit(subItem)">
                    <v-icon>
                      mdi-square-edit-outline
                    </v-icon>
                  </v-btn>
                  <v-btn icon elevation="0" @click="deleteSubItem(subItem)">
                    <v-icon color="red">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="5" style="width:100%;color:#7b7b7b">
                  暂无数据
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      type="edit"
      :account="account"
      :curSubData="curSubAccount"
      :is_readonly="is_readonly"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";
import CreateSubApplication from "@/views/account/drawer/CreateSubApplication";
import EditSubApplication from "@/views/account/drawer/EditSubApplication";

const EDITORS = {
  CreateSubApplication: CreateSubApplication,
  EditSubApplication: EditSubApplication
};

export default {
  name: "DataSource",
  props: ["account"],
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      externals: [],
      subLists: [],
      accountId: null,
      curSubAccount: null,
      is_readonly: null
    };
  },
  mounted() {
    this.getCurIdData();
    this.getCurSubList();
  },
  methods: {
    getCurIdData() {
      if (
        this.account &&
        this.account.externals &&
        this.account.externals.length > 0
      ) {
        let { externals, id } = this.account;
        this.externals = externals;
        this.accountId = id;
      } else {
        this.externals = [];
      }
    },
    //external_id ===》external数组中的id
    unbind(external) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要解绑？ #${external.id} ${external.name}`,
          callback: () => {
            return this.$http
              .post(`api/account/${this.accountId}/unbind`, {
                external_id: external.id
              })
              .delegateTo(api_request)
              .then(() => {
                bus.$emit("refresh");
                return "解绑成功";
              })
              .catch(({ code, message }) => {
                throw `解绑成功：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              })
              .delegateTo(this.$snackbar.delegate);
          }
        })
        .catch(() => {
          return;
        });
    },
    edit(item) {
      this.is_readonly = null;
      this.curSubAccount = item;
      this.sheet_editor = EDITORS["EditSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    show_sheet() {
      this.sheet_editor = EDITORS["CreateSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    editAccount() {
      this.sheet_editor = EDITORS["ACCOUNT"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    editDepartment() {
      this.sheet_editor = EDITORS["DEPARTMENT"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },

    // 子账号应用列表
    getCurSubList() {
      this.subLists = this.account.sub_accounts;
    },
    deleteSubItem({ application_id, id, claims: { username } }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除子账户？ #${id} ${username}`,
          callback: () => {
            return this.$http
              .post(
                `api/application/${application_id}/sub_account/${id}/archive`
              )
              .delegateTo(api_request)
              .then(() => {
                bus.$emit("refresh");
                return "子账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `子账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  watch: {
    account() {
      this.getCurIdData();
      this.getCurSubList();
    }
  },
  components: {
    ConfirmBox,
    CreateSubApplication,
    EditSubApplication
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
