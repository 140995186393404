<template>
  <div class="datasource-container">
    <v-card elevation="0" class="dense">
      <v-card-title>组织机构 </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pl-4">
                  ID
                </th>
                <th class="text-left">
                  {{ $t("account.department.Department Name") }}
                </th>
                <th class="text-left">
                  {{ $t("account.department.Department Description") }}
                </th>
                <th class="text-left">
                  {{ $t("account.department.Department Path") }}
                </th>
                <th class="text-center">
                  {{ $t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="departments.length > 0">
              <tr
                v-for="department in departments"
                :key="department.id"
                class="text-left"
              >
                <td class="pl-4">{{ department.id }}</td>
                <td>{{ department.name }}</td>
                <td>{{ department.description }}</td>
                <td>{{ department.path }}</td>
                <td class="text-center">
                  <v-btn
                    icon
                    elevation="0"
                    title="转岗"
                    @click.stop="show_sheet('edit', 'ACCOUNTGROUP')"
                  >
                    <v-icon>
                      mdi-cog-transfer-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="5" style="width:100%;color:#7b7b7b">
                  暂无数据
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="dense">
      <v-card-title
        >用户组<a @click.stop="show_sheet('edit', 'GROUP')"
          >(修改)</a
        ></v-card-title
      >
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pl-4">
                  ID
                </th>
                <th class="text-left">
                  {{ $t("account.group.Group Name") }}
                </th>
                <th class="text-left">
                  {{ $t("account.group.Group Description") }}
                </th>
                <th class="text-left">
                  {{ $t("account.group.Group Path") }}
                </th>
                <!-- <th class="text-center">
                  {{ $t("Actions") }}
                </th> -->
              </tr>
            </thead>
            <tbody v-if="groups.length > 0">
              <tr v-for="group in groups" :key="group.id" class="text-left">
                <td class="pl-4">{{ group.external_id }}</td>
                <td>{{ group.name }}</td>
                <td>{{ group.description }}</td>
                <td>{{ group.path }}</td>
                <!-- <td class="text-center">
                  <v-btn icon elevation="0" @click="archiveAccount(group)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td> -->
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="4" style="width:100%;color:#7b7b7b">
                  暂无数据
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      :is_modify="current_edit_type === 'edit'"
      type="edit"
      :entity_info="account"
      v-if="account"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
// import DepartmentEditor from "@/views/account/drawer/DepartmentEditor";
import TransferDrawer from "@/views/account/drawer/TransferDrawer";
import AccountGroupsDrawer from "@/views/account/drawer/AccountGroupsDrawer";
import ConfirmBox from "@/components/ConfirmBox";

const EDITORS = {
  ACCOUNTGROUP: TransferDrawer,
  GROUP: AccountGroupsDrawer
};

export default {
  name: "DataSource",
  props: ["account"],
  data() {
    return {
      drawer_status: [false, false],
      current_editor: null,
      current_edit_type: "create",
      sheet_editor: null,
      departments: [],
      groups: []
    };
  },
  created() {
    this.getCurIdData();
  },
  methods: {
    getCurIdData() {
      if (this.account.departments && this.account.groups) {
        let { departments, groups } = this.account;
        this.departments = departments;
        this.groups = groups;
      }
    },
    archiveAccount(group) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除？ #${group.id} ${group.name}`,
          callback: () => {
            return this.$http
              .post(`api/account/${group.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                bus.$emit("refresh");
                return "删除成功";
              })
              .catch(({ code, message }) => {
                throw `删除失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    show_sheet(edit_type, type) {
      this.sheet_editor = EDITORS[type];
      this.current_edit_type = edit_type;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    }
  },
  watch: {
    account() {
      this.getCurIdData();
    }
  },
  components: {
    AccountGroupsDrawer,
    TransferDrawer,
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
